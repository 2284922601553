import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContainer from './components/MainContainer';
import DisclaimerModal from './components/DisclaimerModal';
import InterruptedWindow from './components/InterruptedWindow';
import Blackout from './components/Blackout';
import PrivateBlogContainer from './components/PrivateBlogContainer.js'; // Import the new component
import PrivateBlog from './pages/private-blog/PrivateBlog';
import TerminalHomePage from './pages/server-page/TerminalHomePage.js';
import Terminal from './pages/super-terminal-page/Terminal.js';
import TerminalPage from './pages/mail-terminal/Terminal.js';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<DisclaimerModal />} />
          <Route path="/password-test" element={<PrivateBlogContainer />} />
          <Route path="/private-blog" element={<PrivateBlog />} />
          <Route path="/private-dashboard" element={<TerminalHomePage />} />
          <Route path="/private-website" element={<TerminalPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
