import React from 'react';
import VideoButton from './VideoButton';

function DisclaimerModal({ startGame }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-90 text-green-500">
  <div className="text-center max-w-[640px]">
    <pre style={{ 
      fontFamily: 'monospace', 
      whiteSpace: 'pre', 
      textAlign: 'center', 
      color: '#00FF00',
      margin: '0 auto',
      lineHeight: '1.2'
    }}>
      {'██   ██  ██████  ██████  ███    ██ ███████ ████████     ██       █████  ██████  ███████\n' +
      '██   ██ ██    ██ ██   ██ ████   ██ ██         ██        ██      ██   ██ ██   ██ ██\n' +
      '███████ ██    ██ ██████  ██ ██  ██ █████      ██        ██      ███████ ██████  ███████\n' +
      '██   ██ ██    ██ ██   ██ ██  ██ ██ ██         ██        ██      ██   ██ ██   ██      ██\n' +
      '██   ██  ██████  ██   ██ ██   ████ ███████    ██        ███████ ██   ██ ██████  ███████\n'}
    </pre>
    <p className="mb-4">
      <br></br>PRESENTS
    </p>
    <p className="mb-4">
    An immersive gameplay experience.<br></br> Blending reality with fiction, challenging players with unique interactive narratives <br>
    </br>Ready to
      get started?
    </p>
    <VideoButton />
  </div>
</div>

  );
}

export default DisclaimerModal;
