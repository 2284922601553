// App.js
import React, { useState } from 'react';
import Terminal from './components/Terminal';

function TerminalHomePage() {
  const [stage, setStage] = useState('login');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (username, password) => {
    setUsername(username);
    setPassword(password);
    setStage('security');
  };

  const handleSecurityQuestion = (answer) => {
    if (answer.toLowerCase() === 'quincy') {
      setStage('loggedIn');
    } else {
      alert('Incorrect answer. Please try again.');
    }
  };

  return (
    <div className="bg-gray-900 w-full">
      <div className="flex bg-gray-900 items-center justify-center bg-black text-green-500 font-mono">
        <Terminal
          stage={stage}
          onLogin={handleLogin}
          onAnswer={handleSecurityQuestion}
        />
      </div>
    </div>
  );
}

export default TerminalHomePage;
