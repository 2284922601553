import React, { useState } from 'react';

const BlogPost = ({ title, date, content, author, comments }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 200; // Set a max length for the collapsed content

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayContent = content;

  return (
    <article className="mb-8 p-4 border rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-blue-400 font-serif">{title}</h2>
      <div className="flex font-sans">
        {' '}
        <p className="text-gray-800 font-cursive mb-4 mr-1 text-2xl">
          {author}
          {' | '}
        </p>
        <p className="text-gray-600 font-cursive mb-4 mr-2 text-2xl">{date}</p>
      </div>
      <div
        className="prose text-sm font-sans font-light text-gray-800"
        dangerouslySetInnerHTML={{ __html: displayContent }}
      />
      <CommentsSection comments={comments} />
    </article>
  );
};

const CommentsSection = ({ comments }) => {
  return (
    <>
      {comments?.length > 0 && (
        <div className="mt-4 bg-blue-200 border border-blue-500 p-4 rounded rounded-sm">
          <h3 className="text-md font-sans font-semibold mb-2">Comments</h3>
          {comments.map((comment, index) => (
            <div key={index} className="mb-2 text-sm font-sans font-light">
              <p className="text-gray-800">
                <strong>{comment.author}</strong>: {comment.text}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BlogPost;
