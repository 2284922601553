import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useMediaQuery } from 'react-responsive';
import screenfull from 'screenfull';

const VideoButton = () => {
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  const handleVideoEnd = () => {
    setPlaying(false);
    window.location.href =
      'https://www.reddit.com/user/Flat_Strawberry_8255/comments/1cz787y/emails/'; // Replace with your desired path
  };

  const handleButtonClick = () => {
    window.location.href = 'https://www.youtube.com/live/DeugyN6Yqow';
    // if (screenfull.isEnabled && containerRef.current) {
    //   screenfull.request(containerRef.current).then(() => {
    //     setPlaying(true);
    //   });
    // }
  };

  useEffect(() => {
    if (playing && playerRef.current) {
      playerRef.current.getInternalPlayer().play();
    }
  }, [playing]);

  return (
    <div>
      <button
        className="text-black px-4 py-2 rounded bg-green-500"
        onClick={handleButtonClick}
      >
        Start Game
      </button>
      <div
        ref={containerRef}
        style={{
          position: 'relative',
          paddingTop: '56.25%',
          display: playing ? 'block' : 'none',
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url="https://vimeo.com/949729387?dnt=1"
          playing={playing}
          controls={false}
          onEnded={handleVideoEnd}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: '0', left: '0' }}
        />
      </div>
    </div>
  );
};

export default VideoButton;
