import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import React, { useState, useEffect } from 'react';
import useSyncedState from '../../../hooks/useSyncedState';

const MESSAGING_SPEED = 3000;

const MessengerIcon = () => {
  const [hasRunEscapeCommand, setHasRunEscapeCommand] = useSyncedState(
    'hasRunEscapeCommand',
    false,
  );
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [messageIndex, setMessageIndex] = useState(0);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const predefinedMessages = [
    'Vivian, is that you???',
    "It's me, Trent. I can't believe it. Unless this is someone else...",
    "If it's really you, what was the song we danced to near the river in Paris?",
  ];

  const sendSimulatedMessage = (message, typingTime) => {
    return new Promise((resolve) => {
      setIsTyping(true);
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: message, fromUser: false },
        ]);
        setIsTyping(false);
        resolve();
      }, typingTime);
    });
  };

  useEffect(() => {
    const startMessageSequence = () => {
      setShowMessages(true);
      setMessageIndex(0);
    };

    const timer = setTimeout(startMessageSequence, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showMessages && messageIndex < predefinedMessages.length) {
      const interval = setInterval(() => {
        if (messageIndex < predefinedMessages.length) {
          const message = predefinedMessages[messageIndex];
          sendSimulatedMessage(message, MESSAGING_SPEED);
          setMessageIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showMessages, messageIndex]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    if (hasRunEscapeCommand) {
      sendSimulatedMessage(
        "Babe, I can see from my side that you ran the command. The system admins are probably on alert. DO NOT LISTEN TO THEM. They're trying to stop me. I need you to give me the right access code. Please, I need you to trust me.",
        0,
      ).then(() => {
        sendSimulatedMessage("What's the access code you got babe?");
      });
    }
  }, [hasRunEscapeCommand]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput.trim()) {
      const userMessage = userInput.trim();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: userMessage, fromUser: true },
      ]);
      setUserInput('');
      setInputDisabled(true);
      setIsTyping(true);

      setTimeout(() => {
        if (userMessage.toLowerCase().includes('claudia')) {
          sendSimulatedMessage(
            "Vivian! It's really you. I love you so much!",
            1000,
          ).then(() => {
            sendSimulatedMessage(
              <div>
                Ok. Listen. I don't have much time. I need you to go to{' '}
                <a
                  href="/private-website"
                  className="text-blue-400"
                  target="_blank"
                >
                  this link
                </a>
                . It'll give you backend access to the mindlink mail server.
                Write these credentials down.{' '}
                <p>
                  username: nate.kenna@mindlink-research.com
                  password:rabbitsock1520.
                </p>
                <p>
                  {' '}
                  Once you're in, run the command{' '}
                  <span className="bg-gray-700 p-1 text-white rounded">
                    sudo launchescape
                  </span>
                  . It'll give you an access code that I need you to give to me
                  here.
                </p>
              </div>,
              2000,
            );
          });
        } else if (userMessage.toLowerCase().includes('caroline')) {
          sendSimulatedMessage(
            `That's right! Oh my goodness, it's really you. Vivian!`,
            1000,
          ).then(() => {
            sendSimulatedMessage(
              'Wait, that was on the blog, you could still be anyone.',
              1000,
            ).then(() => {
              sendSimulatedMessage(
                'One more question, my love, just to be safe. What was the name of the first pet we had together?',
                1000,
              );
            });
          });
        } else {
          sendSimulatedMessage("WHO IS THIS? YOU'RE A LIAR", 1000).then(() => {
            setTimeout(() => window.location.reload(), 1000);
          });
        }
        setInputDisabled(false);
      }, 3000);
    }
  };

  const handleClickIcon = () => {
    setShowMessages((prevShowMessages) => !prevShowMessages);
  };

  return (
    <div>
      <div
        className="fixed bottom-4 right-4 p-3 bg-blue-600 rounded-full shadow-lg cursor-pointer"
        onClick={handleClickIcon}
      >
        <ChatBubbleLeftEllipsisIcon className="w-6 h-6 text-white" />
      </div>

      {showMessages && (
        <div className="fixed bottom-16 right-4 bg-white p-4 rounded-lg shadow-lg w-72">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${
                msg.fromUser ? 'justify-start' : 'justify-end'
              }`}
            >
              <p
                className={`mb-2 p-2 rounded ${
                  msg.fromUser
                    ? 'bg-blue-300 text-white text-left'
                    : 'bg-gray-200 text-black text-right'
                }`}
              >
                {msg.text}
              </p>
            </div>
          ))}
          {isTyping && <p className="italic text-gray-500">... is typing</p>}
          {!isTyping && messageIndex >= predefinedMessages.length && (
            <form onSubmit={handleSubmit} className="mt-2">
              <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Type your response..."
                disabled={inputDisabled}
              />
              <button
                type="submit"
                className="mt-2 p-2 bg-blue-600 text-white rounded"
                disabled={inputDisabled}
              >
                Send
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default MessengerIcon;
