import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router';

function PasswordModal({ isOpen, onClose, onSubmit }) {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      //   onClose();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit(password);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50"
      onClick={handleBackgroundClick}
    >
      <div className="bg-white rounded-lg shadow-md p-8 w-full max-w-md relative text-center">
        <h2 className="text-xl font-bold mb-6">Enter Password</h2>
        <div className="relative mb-6 flex">
          <input
            type={showPassword ? 'text' : 'password'}
            className="p-2 w-full border border-gray-400 rounded"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            className="ml-1 mr-2 p-1 bg-gray-200 border border-gray-400 rounded"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeIcon className="w-4 h-4" />
            ) : (
              <EyeSlashIcon className="w-4 h-4" />
            )}
          </button>
        </div>
        <button
          onClick={() => onSubmit(password)}
          className="p-2 w-full border border-gray-400 rounded bg-blue-600 text-white"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

function PrivateBlog() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleModalClose = () => setIsModalOpen(false);
  const handlePasswordSubmit = (password) => {
    if (password.toLowerCase() === 'strawberry') {
      navigate('/private-blog');
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="relative">
      <PasswordModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handlePasswordSubmit}
      />
      {isAuthenticated && (
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-4">Private Blog</h1>
          <p className="text-lg mb-4">
            Welcome to my private blog. Here, I share my personal thoughts and
            experiences.
          </p>
          <article className="mb-6">
            <h2 className="text-2xl font-semibold mb-2">Entry Title 1</h2>
            <p className="text-base">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </article>
          <article>
            <h2 className="text-2xl font-semibold mb-2">Entry Title 2</h2>
            <p className="text-base">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </article>
        </div>
      )}
    </div>
  );
}

export default PrivateBlog;
