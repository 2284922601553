import React, { useState, useEffect } from 'react';

const Alpine = ({ onExit }) => {
  const messages = [
    {
      date: 'Nov 28',
      from: 'Harnek',
      size: '3160',
      subject: 'About Trent',
      body: `I'm worried about that we're being watched. Let's move our communications to this telegram: https://t.me/+MgV1eLyIou8xYmUx `,
    },
    {
      date: 'Nov 28',
      from: 'message@williams.edu',
      size: '10K',
      subject: 'Daily Messages for Tuesday, Nov 28, 2006',
      body: 'Nothing to report today. ',
    },
    {
      date: 'Dec 3',
      from: 'csprd@williams.edu',
      size: '1963',
      subject: '[UTF-8] Package Pickup',
      body: 'Your package can be picked up at the Amydala Auditorium.',
    },
    {
      date: 'Dec 4',
      from: 'Guy Creese',
      size: '13K',
      subject: '[Wiki] Human Control Interface',
      body: 'The HCI page has been updated.',
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [viewingMessage, setViewingMessage] = useState(null);

  const handleKeyDown = (e) => {
    if (viewingMessage !== null) {
      if (e.key === 'Escape') {
        setViewingMessage(null);
      }
      return;
    }

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex,
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex < messages.length - 1 ? prevIndex + 1 : prevIndex,
        );
        break;
      case 'Enter':
        setViewingMessage(messages[selectedIndex]);
        break;
      case 'Escape':
        onExit();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex, viewingMessage]);

  if (viewingMessage) {
    return (
      <div className="bg-black w-full md:w-[720px] text-white p-4 h-screen font-mono">
        <div className="border-b border-white mb-2">
          Message from: {viewingMessage.from}
        </div>
        <div className="overflow-auto">
          <div>Date: {viewingMessage.date}</div>
          <div>Size: {viewingMessage.size}</div>
          <div>Subject: {viewingMessage.subject}</div>
          <div className="mt-2">{viewingMessage.body}</div>
        </div>
        <div className="border-t border-white mt-2 pt-2">
          <span>Press Escape to go back</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-black text-white p-4 h-screen font-mono text-xs md:text-md">
      <div className="border-b border-white mb-2">
        PINE 4.55 Folder: INBOX Message {selectedIndex + 1} of {messages.length}
      </div>
      <div className="w-full overflow-auto">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex w-full ${
              index === selectedIndex ? 'bg-gray-700' : ''
            }`}
          >
            <div className="w-24 truncate">
              {index + 1} {msg.date}
            </div>
            <div className="w-32 truncate">{msg.from}</div>
            <div className="flex-1 truncate">{msg.subject}</div>
          </div>
        ))}
      </div>
      <div className="border-t border-white mt-2 pt-2">
        <div className="flex justify-between">
          <span>PrevMsg</span>
          <span>NextMsg</span>
          <span>PrevPage</span>
          <span>Delete</span>
          <span>Reply</span>
          <span>Forward</span>
        </div>
      </div>
      <div className="mt-2">Press escape to exit</div>
    </div>
  );
};

export default Alpine;
