import React from 'react';
import Header from './Header';
import Footer from './Footer';
import HeroArea from './HeroArea';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <HeroArea />
      <main
        style={{
          background:
            '#66bb33 url(//www.blogblog.com/1kt/watermark/body_background_flower.png) repeat scroll top center',
        }}
        className="flex justify-center flex-grow"
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
