import React, { useState, useEffect } from 'react';
import { bootMessages } from '../support/messages';

function Terminal({ stage, onLogin, onAnswer }) {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      if (i < bootMessages.length) {
        setMessages((prev) => [...prev, bootMessages[i]]);
        i++;
      } else {
        clearInterval(interval);
      }
    }, Math.floor(Math.random() * (200 - 50 + 1)) + 50); // Show one message every 100-300 milliseconds
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim().toLowerCase() === 'quincy') {
      onLogin(); // Call the onLogin function to proceed to the next page
    } else {
      // Optionally handle incorrect input
      setMessages((prev) => [
        ...prev,
        { text: 'Incorrect. Please try again.', color: 'red' },
      ]);
    }
    setInput(''); // Clear the input field
  };

  return (
    <div className="bg-black p-4 rounded-lg w-full max-w-3xl overflow-hidden">
      <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
        {messages.map(
          (msg, i) =>
            msg && (
              <div key={i} style={{ color: msg.color }}>
                {msg.text}
              </div>
            ),
        )}
      </pre>
      <form onSubmit={handleSubmit}>
        <input
          className="bg-black text-green-500 border-none focus:outline-none w-full"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          autoFocus
        />
      </form>
    </div>
  );
}

export default Terminal;
