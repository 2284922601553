import React from 'react';
import Layout from './components/Layout';
import MainContent from './components/MainContent';
import MessengerIcon from './components/MessengerIcon';

const PrivateBlog = () => {
  return (
    <Layout>
      <MainContent />
      <MessengerIcon />
    </Layout>
  );
};

export default PrivateBlog;
