const bootMessages = [
  { text: 'Raspberry Pi Bootloader', color: 'lightgray' },
  { text: 'Read start.elf bytes 123456', color: 'lightgray' },
  { text: 'Read fixup.dat bytes 54321', color: 'lightgray' },
  { text: 'Loading kernel.img', color: 'lightgray' },
  { text: 'Loading dtb file', color: 'lightgray' },
  {
    text: '[    0.000000] Booting Linux on physical CPU 0x0',
    color: 'lightgreen',
  },
  {
    text: '[    0.000000] Linux version 5.4.51-v7l+ (dom@buildbot) (gcc version 8.3.0 (Raspbian 8.3.0-6+rpi1)) #1333 SMP Thu Aug 6 16:23:13 BST 2020',
    color: 'lightgreen',
  },
  {
    text: '[    0.000000] CPU: ARMv7 Processor [410fd083] revision 3 (ARMv7), cr=30c5383d',
    color: 'lightgreen',
  },
  {
    text: '[    0.000000] Memory policy: Data cache writealloc',
    color: 'lightgreen',
  },
  {
    text: '[    0.000000] On node 0 totalpages: 1024000',
    color: 'lightgreen',
  },
  {
    text: '[    0.000000]   DMA zone: 1024000 pages, LIFO batch:31',
    color: 'lightgreen',
  },
  {
    text: '[    1.000000] EXT4-fs (mmcblk0p2): mounted filesystem with ordered data mode. Opts: (null)',
    color: 'lightblue',
  },
  {
    text: '[    1.000000] VFS: Mounted root (ext4 filesystem) on device 179:2.',
    color: 'lightblue',
  },
  {
    text: '[    2.000000] systemd[1]: Started Journal Service.',
    color: 'lightyellow',
  },
  {
    text: '[    2.000000] systemd[1]: Reached target System Time Synchronized.',
    color: 'lightyellow',
  },
  {
    text: '[    2.000000] systemd[1]: Listening on Journal Socket.',
    color: 'lightyellow',
  },
  // More dummy messages
  { text: '[    2.500000] random: crng init done', color: 'lightyellow' },
  { text: '[    2.600000] Bluetooth: Core ver 2.22', color: 'lightgreen' },
  {
    text: '[    2.650000] NET: Registered protocol family 31',
    color: 'lightgreen',
  },
  {
    text: '[    2.700000] Bluetooth: HCI device and connection manager initialized',
    color: 'lightgreen',
  },
  {
    text: '[    2.750000] Bluetooth: HCI socket layer initialized',
    color: 'lightgreen',
  },
  {
    text: '[    2.800000] Bluetooth: L2CAP socket layer initialized',
    color: 'lightgreen',
  },
  {
    text: '[    2.850000] Bluetooth: SCO socket layer initialized',
    color: 'lightgreen',
  },
  {
    text: '[    2.900000] usbcore: registered new interface driver btusb',
    color: 'lightgreen',
  },
  {
    text: '[    3.000000] systemd[1]: Started Login Service.',
    color: 'lightyellow',
  },
  {
    text: '[    3.050000] systemd[1]: Reached target Multi-User System.',
    color: 'lightyellow',
  },
  {
    text: '[    3.100000] systemd[1]: Started Daily Cleanup of Temporary Directories.',
    color: 'lightyellow',
  },
  {
    text: '[    3.200000] systemd[1]: Listening on Avahi mDNS/DNS-SD Stack Activation Socket.',
    color: 'lightyellow',
  },
  {
    text: '[    3.300000] systemd[1]: Reached target Timers.',
    color: 'lightyellow',
  },
  {
    text: '[    3.400000] systemd[1]: Listening on D-Bus System Message Bus Socket.',
    color: 'lightyellow',
  },
  {
    text: '[    3.500000] systemd[1]: Reached target Sockets.',
    color: 'lightyellow',
  },
  {
    text: '[    3.600000] systemd[1]: Reached target Basic System.',
    color: 'lightyellow',
  },
  {
    text: 'Raspberry Pi login: nate.kenna@gmail.com',
    color: 'lightgreen',
  },
  {
    text: 'Password:',
    color: 'lightgreen',
  },
  {
    text: 'This account has been compromised, please answer the following security question to continue.',
    color: 'red',
  },
  {
    text: "Security Question: What's the name of my dog?",
    color: 'lightgreen',
  },
];

export { bootMessages };
