import React from 'react';

const HeroArea = () => {
  return (
    <div
      style={{
        background:
          '#77ccee url(//www.blogblog.com/1kt/watermark/body_background_flower.png) repeat scroll top left',
      }}
    >
      <div
        style={{
          background:
            'transparent url(//www.blogblog.com/1kt/watermark/main_overlay_flower.png) repeat-x scroll bottom left',
        }}
      >
        <div className="flex justify-center text-green-700 max-w-[1080]">
          <div className="flex-col justify-center py-16">
            <div className="font-cursive text-8xl text-center ">
              {' '}
              Vivian and Trent's <b>Private</b> Blog{' '}
            </div>
            <div className="font-sans text-center"> Two nerds one blog </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
