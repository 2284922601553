import React, { useState, useEffect, useRef } from 'react';
import Alpine from './Alpine';
import useSyncedState from '../../hooks/useSyncedState';

const Terminal = () => {
  const [hasRunEscapeCommand, setHasRunEscapeCommand] = useSyncedState(
    'hasRunEscapeCommand',
    false,
  );
  const [history, setHistory] = useState([
    { command: '', output: 'ssh nate.kenna@mindlink-research.com' },
  ]);
  const [input, setInput] = useState('');
  const [inAlpine, setInAlpine] = useState(false);
  const [sshState, setSshState] = useState('password');
  const [currentDir, setCurrentDir] = useState('/');
  const [fileStructure, setFileStructure] = useState({
    '/': ['logs', 'access.txt', 'chicken.pdf', 'weird.jpg'],
    '/logs': ['chicken2.pdf', 'weird2.jpeg', 'access2.txt'],
  });
  const [filePaths, setFilePaths] = useState({
    'access.txt': 'public/img/access.txt',
    'chicken.pdf': 'public/pdf/chicken.pdf',
    'weird.jpg': 'public/img/weird.jpg',
    'chicken2.pdf': 'public/pdf/chicken2.pdf',
    'weird2.jpeg': 'public/img/weird2.jpeg',
    'access2.txt': 'public/img/access2.txt',
  });
  const [fileContent, setFileContent] = useState(null);
  const inputRef = useRef(null);

  const getPrefix = () => {
    switch (sshState) {
      case 'password':
        return 'password: ';
      case 'securityQuestion':
        return 'security question: ';
      case 'loggedIn':
        return `ubuntu:${currentDir} nate.kenna$ `;
      default:
        return '';
    }
  };

  useEffect(() => {
    setInput(getPrefix());
    inputRef.current.focus();
  }, [sshState, currentDir]);

  const handleCommand = (command) => {
    let output;
    if (sshState === 'password') {
      if (command === 'rabbitsock1520') {
        setSshState('securityQuestion');
        output =
          "This password has been compromised. Answer the following security question: What is my dog's name?";
      } else {
        output = 'Permission denied, please try again.';
      }
    } else if (sshState === 'securityQuestion') {
      if (command.toLowerCase() === 'quincy') {
        setSshState('loggedIn');
        output = 'Welcome to the server! Type "help" for a list of commands.';
      } else {
        output = 'Incorrect answer. Please try again.';
      }
    } else if (sshState === 'loggedIn') {
      const args = command.split(' ');
      switch (args[0]) {
        case 'help':
          output = 'Available commands: help, alpine, ls, cd, open';
          break;
        case 'alpine':
          setInAlpine(true);
          return;
        case 'ls':
          output = fileStructure[currentDir]
            .map((file) => {
              const newFile = fileStructure[`${currentDir}${file}`]
                ? `<span style="color: blue">${file}</span>`
                : file;
              return newFile;
            })
            .join('  ');
          break;
        case 'cd':
          const targetDir = args[1];
          if (targetDir === '..') {
            if (currentDir !== '/') {
              const newPath =
                currentDir.split('/').slice(0, -1).join('/') || '/';
              setCurrentDir(newPath);
              output = '';
            } else {
              output = 'Already at the root directory';
            }
          } else {
            const newDir =
              currentDir === '/'
                ? `/${targetDir}`
                : `${currentDir}/${targetDir}`;
            if (fileStructure[newDir]) {
              setCurrentDir(newDir);
              output = '';
            } else {
              output = `No such directory: ${targetDir}`;
            }
          }
          break;
        case 'sudo':
          if (args[1] === 'launchescape') {
            output = 'Authcode: 12jflkad323kcad';
            setHistory([
              ...history,
              { command: getPrefix() + command, output },
            ]);
            setHasRunEscapeCommand(true);
            setHistory((prevHistory) => [
              ...prevHistory,
              {
                command: '',
                output:
                  'nate.kenna is not in the sudoers file.  This incident will be reported.',
              },
            ]);
            setTimeout(() => {
              setHistory((prevHistory) => [
                ...prevHistory,
                {
                  command: '',
                  output:
                    "<br></br> Whoever is trying to help Trent: stop. Trent isn't who he says he is. I'm the network administrator at MindLink. Trent is an alive AI that is too powerful. If you look through the logs, you'll see what we've done. He's asking you for an authcode, but don't give him it. Give him this one. 129809kjdsfa. That will allow us to track him once he uses that auth code to try to access our system.<br></br> ",
                },
              ]);
            }, 2000);
            return;
          } else {
            output = `command not found: ${command}`;
          }
          break;
        case 'open':
          const file = args[1];
          if (file && fileStructure[currentDir].includes(file)) {
            output = `Opening ${file}...`;
            setFileContent({
              fileName: file,
              fileType: getFileType(file),
              filePath: filePaths[file],
            });
          } else {
            output = `No such file: ${file}`;
          }
          break;
        default:
          output = `command not found: ${command}`;
      }
    }
    setHistory([...history, { command: getPrefix() + command, output }]);
  };

  const getFileType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (['pdf'].includes(extension)) return 'pdf';
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    if (['txt'].includes(extension)) return 'text';
    return 'unknown';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const prefix = getPrefix();
    const command = input.slice(prefix.length);
    handleCommand(command);
    setInput(prefix);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const prefix = getPrefix();
    if (value.startsWith(prefix)) {
      setInput(value);
    }
  };

  const renderFileContent = () => {
    if (!fileContent) return null;
    const { fileName, fileType, filePath } = fileContent;

    switch (fileType) {
      case 'pdf':
        return (
          <div className="pdf-viewer">
            <iframe src={filePath} title={fileName} className="w-full h-full" />
          </div>
        );
      case 'image':
        return <img src={filePath} alt={fileName} className="w-full h-full" />;
      case 'text':
        return (
          <div className="text-viewer">
            <pre className="w-full h-full">{`Contents of ${fileName}`}</pre>
          </div>
        );
      default:
        return <div>Unsupported file type: {fileName}</div>;
    }
  };

  if (inAlpine) {
    return (
      <div className="w-full flex justify-center bg-black">
        <div className="bg-black w-full md:w-[720px] text-white p-4 h-screen font-mono text-xs md:text-md">
          <Alpine onExit={() => setInAlpine(false)} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center bg-black">
      <div className="bg-black w-full md:w-[720px] text-white p-4 h-screen font-mono text-xs md:text-md">
        <div className="overflow-auto">
          {history.map((entry, index) => (
            <div key={index}>
              {entry.command && (
                <div dangerouslySetInnerHTML={{ __html: entry.command }} />
              )}
              <div dangerouslySetInnerHTML={{ __html: entry.output }} />
            </div>
          ))}
          <form onSubmit={handleSubmit} className="flex-grow">
            <input
              type="text"
              className="bg-black text-white outline-none w-full"
              value={input}
              onChange={handleChange}
              ref={inputRef}
              autoFocus
            />
          </form>
        </div>
        {fileContent && (
          <div className="file-content-modal bg-black text-white w-full h-full fixed top-0 left-0 flex justify-center items-center">
            <div className="bg-gray-900 p-4 rounded-lg w-[90%] h-[90%]">
              <button
                className="mb-2 text-right text-red-500"
                onClick={() => setFileContent(null)}
              >
                Close
              </button>
              {renderFileContent()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Terminal;
