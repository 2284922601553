import React from 'react';

const Header = () => {
  return (
    <header className="bg-green-700 text-white px-4 py-2">
      <div className="mx-auto flex justify-between items-center">
        <h1 className="text-md">Trent and Vivian's Private Blog</h1>
        <nav>
          <ul className="flex space-x-4 text-sm">
            <li>
              <a className="cursor-pointer hover:underline">Home</a>
            </li>
            <li>
              <a className="cursor-pointer hover:underline">About</a>
            </li>
            <li>
              <a className="cursor-pointer hover:underline">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
