import React from 'react';

const Sidebar = () => {
  return (
    <aside className="w-full lg:w-1/3 p-4 rounded-lg">
  <h2 className="text-xl font-bold mb-4">Our Special Place</h2>
  <p className="mb-4">
    {' '}
    <p>
      🍓 Just for us 🍓 V&T
    </p>
    <br></br>
    <p>
      <a href="#" className="text-blue-500 hover:underline">
        <img src="assets/vivian_chicken_trent.jpg" alt="vivian chicken trent" className="w-72 h-72" />
      </a>
    </p>
    <br></br>
    <p>
      {' '}
      Tech whispers through garden's green, Home, a smart hive's dream.
    </p>
  </p>
</aside>
  );
};

export default Sidebar;
