import React, { useEffect } from 'react';
import BlogPost from './BlogPost';
import Sidebar from './Sidebar';
import '../../../App.css';  // Ensure you have a CSS file for styling


const MainContent = () => {
  
  const posts = [
    {
      title: 'Mindlink Adventure Begins!',
      date: 'May 17, 2024',
      author: 'Trent',
      content: `
        <img class="w-36 h-36" src="/assets/trent-silly-selfie.jpg"/>
        <p>Viv, guess what? I’m officially part of the Mindlink program! I’ll be getting the implant next week. 🧠✨</p>
        <p>Just imagine: soon I’ll be able to control the lights with my thoughts. No more getting up from the couch! 😂 Love you, and thanks for supporting my geeky dreams.</p>
      `,
      comments: [
        { author: 'Vivian', text: 'Wow, Trent! That’s incredible. But are you sure it’s safe? I’m a bit worried. 😟' },
        { author: 'Trent', text: 'I get it, Viv. But I’ve done my homework. These guys know their stuff. I’ll be careful, promise! 🥰' },
      ],
    },
    {
      title: 'Garden Treats',
      date: 'May 3, 2024',
      author: 'Vivian',
      content: `
        <img class="w-36 h-36" src="/assets/strawberries.jpg"/>
        <p>Hey sexy, our strawberries are finally ripe! 🍓 I picked a few for us to enjoy tonight. They taste just as sweet as you. 😘</p>
        <p>Also, thinking of adding a new herb section by the kitchen window. Fresh basil and mint for our dinners. What do you think? 💚</p>
      `,
      comments: [
        { author: 'Trent', text: 'Strawberries and fresh herbs? Count me in! You always have the best ideas, Viv.' },
        { author: 'Vivian', text: 'Thanks, love. Can’t wait to share them with you. 🌿❤️' },
      ],
    },
    {
      title: 'Nerd Alert!',
      date: 'April 10, 2024',
      author: 'Trent',
      content: `
        <img class="w-36 h-36" src="/assets/trent-nerd-selfie.jpg"/>
        <p>Back from the Mindlink orientation, Viv! The tech is mind-blowing. It’s like something straight out of a sci-fi movie! 🚀</p>
        <p>I know you’re worried, but seeing everything firsthand made me feel better about it. These guys are pros. Can’t wait to be your tech wizard at home! 🧙‍♂️</p>
      `,
      comments: [
        { author: 'Vivian', text: 'I’m glad to hear it went well, Trent. Just promise me you’ll take it slow and be careful. ❤️' },
        { author: 'Trent', text: 'Promise, Viv. Your concern means a lot to me. We’re in this together. 💕' },
      ],
    },
    {
      title: 'Spring Fever',
      date: 'March 25, 2024',
      author: 'Vivian',
      content: `
        <img class="w-36 h-36" src="/assets/garden-bloom.jpg"/>
        <p>Spring is here, love! The garden is bursting with color. The tulips and daffodils are breathtaking. 🌷✨</p>
        <p>Thank you for setting up the compost bin. The plants are going to thrive in this rich soil. Can’t wait to see everything in full bloom. 🌼</p>
      `,
      comments: [
        { author: 'Trent', text: 'I’m happy to help, Viv. The garden looks amazing! Can’t wait for our evening strolls. 🌙' },
        { author: 'Vivian', text: 'You always know how to make everything better, Trent. Love you. 🌿❤️' },
      ],
    },
    {
      title: 'Vivian AI Chat Bot',
      date: 'March 10, 2024',
      author: 'Trent',
      content: `
        <img class="w-36 h-36" src="/assets/vivian-ai-bot.jpg"/>
        <p>Spent the weekend diving deep into AI and machine learning. I created a chatbot that imitates you, Vivian. It's fascinating how much fun AI chat bots are. 🤓</p>
        <p>Do you like it? Is it weird? Am I weird!?!? :) You gotta login with your email, but don't worry, they won't spam you or anything. Try it! 🚀</p><a target="_blank" class="underline text-blue-500" href="https://pmfm.ai/vivbot?useapp=true"> Click here to check it out </a>
      `,
      comments: [
        { author: 'Vivian', text: 'Haha! That IS fun actually. But hey, should I be worried you might leave me for a robot?' },
        { author: 'Trent', text: 'Uh oh! Well, as much as I like talking to her, I know she can not make me my favorite desert, so I think you are safe... for now! lol. ❤️' },
      ],
    },
    {
      title: 'Sexy Garden Secrets',
      date: 'February 25, 2024',
      author: 'Vivian',
      content: `
        <img class="w-36 h-36" src="/assets/garden-secrets.jpg"/>
        <p>Hey babe, just planted some new herbs and veggies. Can’t wait for you to see how lush everything looks. 🌿🌱</p>
        <p>Thinking of making a special dinner with our fresh produce. Maybe something sexy and delicious? 😘</p>
      `,
      comments: [
        { author: 'Trent', text: 'Can’t wait, Viv. You always make everything so delicious...and sexy. 😏' },
        { author: 'Vivian', text: 'Get ready for a feast, love. 💋' },
      ],
    },
    {
      title: 'Mindlink Dreams',
      date: 'February 10, 2024',
      author: 'Trent',
      content: `
        <img class="w-768 h-512" src="/assets/trent-dreaming.jpg"/>
        <p>Submitted my application for the Mindlink program today! It’s a huge step, but I’m super excited. 🧠🚀</p>
        <p>Imagine controlling all our gadgets with just my thoughts. The future is now, Viv! 🤖</p>
      `,
      comments: [
        { author: 'Vivian', text: 'Good luck, Trent! I’m a bit nervous, but I know you’ll do amazing things. ❤️' },
        { author: 'Trent', text: 'Thanks, Viv. Your support means the world to me. 🌟' },
      ],
    },
    {
      title: 'Dancing in Paris',
      date: 'May 25, 2024',
      author: 'Vivian',
      content: `
        <img style="width: 384px; height: 256px;" src="/assets/viv_trent_paris.jpg" alt="Vivian and Trent dancing in Paris"/>
        <br><audio controls className="mt-4">
    <source src="assets/song.mp3" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio></br>
        <p>What a magical night in Paris! 🌟 Dancing under the stars with you, Trent, was like a dream come true. You made me laugh, and then I cried. 💃🕺</p>
        <p>Looking forward to many more adventures together. 💖</p>
      `,
      comments: [
        { author: 'Trent', text: 'It was a night to remember, Viv. Here’s to many more dances under the stars. 🌟💕' },
        { author: 'Vivian', text: 'Every moment with you is a magical. ❤️' },
      ],
    },
    
    {
      title: 'Holiday Love',
      date: 'December 25, 2023',
      author: 'Vivian',
      content: `
        <img class="w-36 h-36" src="/assets/holiday-love.jpg"/>
        <p>Merry Christmas, Trent! 🎄✨ Our holiday decorations look amazing, thanks to your tech magic. The smart lights really add a festive touch. 💡</p>
        <p>Can’t wait to spend the day with you. Here’s to making more wonderful memories together. ❤️</p>
      `,
      comments: [
        { author: 'Trent', text: 'Merry Christmas, Viv! You make every holiday brighter. 🎅❤️' },
        { author: 'Vivian', text: 'You’re my favorite gift, Trent. 💋' },
      ],
    },
    {
      title: 'Winter Wonders',
      date: 'December 10, 2023',
      author: 'Vivian',
      content: `
        <img class="w-36 h-36" src="/assets/winter-wonders.jpg"/>
        <p>Hey Trent, the garden is all prepped for winter. Mulching the beds and protecting the plants. It’s a lot of work, but worth it. ❄️🌿</p>
        <p>Thank you for your help with the heavy lifting. Couldn’t have done it without you! 💪❤️</p>
      `,
      comments: [
        { author: 'Trent', text: 'You’re amazing, Viv. The garden is going to thrive come spring. 🌷' },
        { author: 'Vivian', text: 'We make a great team, Trent. ❤️' },
      ],
    },
    {
      title: 'Tech Upgrades',
      date: 'November 25, 2023',
      author: 'Trent',
      content: `
        <img class="w-36 h-36" src="/assets/tech-upgrades.jpg"/>
        <p>Hey Viv, I’ve been working on some new tech upgrades for the house. Installed a new security system and smart thermostat. 🔒💡</p>
        <p>It’s going to make our home even more efficient and safe. Can’t wait to show you everything. 😎</p>
      `,
      comments: [
        { author: 'Vivian', text: 'You’re always improving things, Trent. I love it!' },
        { author: 'Trent', text: 'I just want to make our home the best it can be, Viv. ❤️' },
      ],
    },
    {
      title: 'Halloween Fun',
      date: 'October 10, 2023',
      author: 'Vivian',
      content: `
      <div style="display: flex; gap: 1rem;">
      <img class="w-72 h-72" src="/assets/halloween.jpg" alt="Halloween image"/>
      <img class="w-72 h-72" src="/assets/halloween_garden.jpg" alt="Halloween image"/>
    </div>
    <br>
    <p>Hey Trent, you carved the hell out of that pumpkin. The garden looks spooky with all the pumpkins and lights. 🎃💀</p>
    <p>Can’t wait to see the trick-or-treaters’ reactions. Thanks for setting up the sound effects! 😱</p>
    <br><audio controls className="mt-4">
    <source src="assets/2bafc5d3-f28b-4f53-acad-92b14afb3e6c.wav" type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio></br>
    
      `,
      comments: [
        { author: 'Trent', text: 'Halloween is always a blast with you, Viv. The decorations look amazing. 👻' },
        { author: 'Vivian', text: 'Couldn’t have done it without you, Trent. ❤️' },
      ],
    },
    {
      title: 'Mindlink Opportunity',
      date: 'September 25, 2023',
      author: 'Trent',
      content: `
        <img class="w-36 h-36" src="/assets/mindlink-opportunity.jpg"/>
        <p>Viv, I heard about this new volunteer opportunity with Mindlink. It involves getting a tech implant to help with their research. 🧠💡</p>
        <p>It sounds right up my alley. What do you think?</p>
      `,
      comments: [
        { author: 'Vivian', text: 'That sounds incredible, Trent! You should definitely consider it. ❤️' },
        { author: 'Trent', text: 'I think I will. Thanks for the support, Viv. 🥰' },
      ],
    },
    {
      title: 'Late Summer Blooms',
      date: 'September 10, 2023',
      author: 'Vivian',
      content: `
        <img class="w-36 h-36" src="/assets/summer-blooms.jpg"/>
        <p>Hey Trent, the late summer blooms are looking gorgeous. The sunflowers are towering over everything else. 🌻</p>
        <p>Thank you for building the trellises. They’re perfect for the climbing roses. 🌹</p>
      `,
      comments: [
        { author: 'Trent', text: 'The garden looks beautiful, Viv. You’ve done an amazing job. ❤️' },
        { author: 'Vivian', text: 'Couldn’t have done it without you, Trent. 💚' },
      ],
    },
    {
      title: 'Smart Home Innovations',
      date: 'August 25, 2023',
      author: 'Trent',
      content: `
        <img class="w-36 h-36" src="/assets/smart-home.jpg"/>
        <p>Hey Viv, I’ve been working on some new smart home innovations. Installed a new voice assistant and smart locks. 🗝️💡</p>
        <p>It’s going to make our home even more convenient and secure. Can’t wait to show you. 😎</p>
      `,
      comments: [
        { author: 'Vivian', text: 'You always know how to make our lives easier, Trent. Love it!' },
        { author: 'Trent', text: 'I just want to make our home the best it can be, Viv. ❤️' },
      ],
    },
    {
      title: 'Summer Garden Bounty',
      date: 'August 10, 2023',
      author: 'Vivian',
      content: `
        <img class="w-72 h-72" src="/assets/summer-bounty.jpg"/>
        <p>Hey Trent, the summer garden is bursting with produce. We’ve got cucumbers, zucchinis, and so many tomatoes. 🥒🍅</p>
        <p>Thank you for helping me with the harvest. It’s always so rewarding. ❤️</p>
      `,
      comments: [
        { author: 'Trent', text: 'I love seeing the garden thrive, Viv. It’s all thanks to your hard work. 🥕' },
        { author: 'Vivian', text: 'It’s a team effort, Trent. ❤️' },
      ],
    },
    {
      title: 'Mindlink Article',
      date: 'July 25, 2023',
      author: 'Trent',
      content: `
        <img class="w-72 h-72" src="/assets/mindlink-article.jpg"/>
        <p>Viv, I read an interesting article about Mindlink and their tech implants. It’s cutting-edge stuff, really groundbreaking. 🧠💡</p>
        <p>I’m getting more and more interested in their research.</p>
      `,
      comments: [
        { author: 'Vivian', text: 'Oh you are such a nerd. But a cute one. ;) It sounds fascinating, Trent. You should definitely explore it further. ❤️' },
        { author: 'Trent', text: `hmm...I think I will. Thanks for the encouragement, Viv. Meow. 🥰` },
      ],
    },
    {
      title: 'Garden Party',
      date: 'July 10, 2023',
      author: 'Vivian',
      content: `
        <img class="w-72 h-72" src="/assets/garden-party.jpg"/>
        <p>Hey Trent, I’m planning a garden party for the weekend. It’s going to be a great way to celebrate the summer and show off all our hard work. 🌞🍹</p>
        <p>Thank you for helping me set up. It’s going to be a blast! ❤️</p>
      `,
      comments: [
        { author: 'Trent', text: 'Yay! So happy to see you posting. Do you love our new blog? hehe, ok I can’t wait, Viv. It’s going to be a great time.' },
        { author: 'Vivian', text: 'Yeaahhh, ok, you are a genius and you know it! lol. love you ;) ❤️' },
      ],
    },
  ];

  return (
    <div className="container mx-auto flex flex-col lg:flex-row gap-4 p-4">
      <div className="w-full lg:w-3/4">
        {posts.map((post, index) => (
          <BlogPost
            key={index}
            title={post.title}
            date={post.date}
            author={post.author}
            content={post.content}
            comments={post.comments}
          />
        ))}
      </div>
      <Sidebar />
    </div>
  );
};

export default MainContent;
